






import BoostsCondition from "./BoostsCondition";
import { BoostsSourceEnum } from "./types";

import { Component, Prop, Vue } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({})
export default class BoostsConditionValue extends Vue {
  @Prop() model!: BoostsCondition

  get label() {
    let res = ''

    if (this.model.source === BoostsSourceEnum.Custom) {
      res = this.$t(`boosts_condition_label_custom`, [ this.model.custom ]).toString()
    } else {
      res = this.$t(`boosts_condition_label_${ snakeCase(this.model.source) }`).toString()
    }

    if (this.model.required) {
      res += " " + this.$t('boosts_condition_required_true').toString().toLowerCase()
    } else {
      res += " " + this.$t('boosts_condition_required_false').toString().toLowerCase()
    }

    return res + " " + this.$t('boosts_condition_label_from_user_end').toString()
  }
}
